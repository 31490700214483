@import '../../styles/propertySets.css';

.root {
  display: flex;
  flex-direction: column;
}

.nowrap {
  white-space: nowrap;
}

.lineItem {
  @apply --marketplaceH4FontStyles;
  margin: 0;

  @media (--viewportMedium) {
    padding-top: 4px;
    padding-bottom: 1px;
  }

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.bookingPeriod {
  flex: 1 1;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 6px;

  @media (--viewportMedium) {
    padding-bottom: 8px;
  }
}

.bookingPeriodSectionRigth {
  text-align: right;
}

.dayLabel {
  @apply --marketplaceH5FontStyles;
  margin: 0;
  color: var(--matterColorAnti);
  line-height: 24px;
  padding-top: 2px;
  padding-bottom: 4px;

  @media (--viewportMedium) {
    padding-top: 1px;
    padding-bottom: 7px;
  }
}

.dayInfo {
  @apply --marketplaceButtonFontStyles;
}

.subTotalLineItem {
  @apply --marketplaceH4FontStyles;
  font-weight: var(--fontWeightBold);
  margin: 0;
  padding: 4px 0 1px 0;

  @media (--viewportMedium) {
    padding: 4px 0 1px 0;
  }

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.lineItemTotal {
  @apply --marketplaceH4FontStyles;
  margin: 0;
  padding-top: 6px;
  padding-bottom: 6px;

  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;

  @media (--viewportMedium) {
    padding-top: 7px;
    padding-bottom: 1px;
  }
}

.lineItemTotalM {
  margin-top: 15px;
  margin-bottom: 25px;
}

.itemLabel {
  @apply --marketplaceSmallFontStyles;
  font-weight: var(--fontWeightRegular);
  font-size: 16px;
  flex: 1;
}

.itemValue {
  @apply --marketplaceSmallFontStyles;
  font-weight: var(--fontWeightSemiBold);
  font-size: 16px;
  margin: 0 0 0 10px;
}

.itemValueM {
  font-weight: 400;
}

.orderBreakdownTitle {
  margin-bottom: 3px;
}

.totalDivider {
  /* dimensions */
  width: 100%;
  height: 1px;
  margin: 12px 0 5px 0;

  border: none;
  background-color: var(--matterColorAnti);

  @media (--viewportMedium) {
    margin: 25px 0;
  }
}

.subTitle {
  margin-bottom: 7px;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  margin-top: 7px;
}

.totalLabel {
  @apply --marketplaceSmallFontStyles;
  font-size: 20px;
  font-weight: var(--fontWeightRegular);
}

.totalPrice {
  @apply --marketplaceButtonFontStyles;
  margin: 0 0 0 10px;
  padding-top: 0;
  font-size: 20px;
}

.feeInfo {
  @apply --marketplaceTinyFontStyles;
  flex-shrink: 0;
  margin: 0;
  color: var(--matterColorAnti);
  padding-top: 4px;
  padding-bottom: 14px;

  @media (--viewportMedium) {
    padding-top: 11px;
    padding-bottom: 21px;
    font-size: 14px;
  }
}

.daysList {
  /*padding: 20px 0 19px 15px;*/
}

.daysItem {
  display: flex;
  width: 100%;
  position: relative;
  border-bottom: 1px solid var(--colorLightGrey);
  padding: 20px 0 19px 15px;
}

.daysItemHoliday {
  background: var(--lightFailColor);
}

.daysSpan {
  flex: 0 0 25%;
  max-width: 25%;
  font-size: 14px;

  @media (--viewportMedium) {
    font-size: 16px;
  }
}

.tableHeaderList {
  display: flex;
  width: 100%;
  border-bottom: 1px solid var(--colorLightGrey);
  padding: 20px 0 19px 0;
  margin: 0;

  @media (--viewportMedium) {
    padding: 20px 0 19px 15px;
  }
}

.tableHeaderItem {
  flex: 0 0 10%;
  max-width: 10%;
  font-size: 14px;
  color: var(--matterColorAnti);

  @media (--viewportMedium) {
    font-size: 16px;
  }
}

.tableHeaderItem1, .daysSpan1 {
  flex: 0 0 22%;
  max-width: 22%;
}

.tableHeaderItem2, .daysSpan2 {
  flex: 0 0 25%;
  max-width: 25%;

  @media (--viewportMedium) {
    flex: 0 0 28%;
    max-width: 28%;
  }
}

.tableHeaderItem3, .daysSpan3 {
  flex: 0 0 39%;
  max-width: 39%;

  @media (--viewportMedium) {
    flex: 0 0 40%;
    max-width: 40%;
  }
}

.modalTitle {
  display: flex;
  justify-content: center;
}

.subItem {
  font-size: 16px;
  font-weight: 400;
}

.subItemLink {
  color: var(--marketplaceColor);
  cursor: pointer;
}

.item {
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
}

.itemBig {
  margin-bottom: 7px;
}



