@import '../../../styles/propertySets.css';

.root {
  position: relative;
  display: inline-block;
}

.fieldGroup {
  margin: 0;
  padding: 0;
  border: none;
}

.fieldGroupPlain {
  margin: 0;
  padding-left: 12px;
  padding-bottom: 6px;
  border: none;

@media (--viewportMedium) {
  padding: 3px 0 29px 12px;
  padding: 4px 0 4px 12px;
}
}

.list {
  margin: 0;
}

.item {
  padding: 2px 0;

  /* Fix broken multi-column layout in Chrome */
  page-break-inside: avoid;

@media (--viewportMedium) {
  padding: 4px 0;
}
}

.submitButton {
  background: var(--successColorMiddle);
  color: var(--successColor);
}

.filterPanel {
  margin-top: 30px;

  @media (--viewportMedium) {
    position: absolute;
    background: white;
    /*overflow: hidden;*/
    z-index: 20;
    margin-top: -267px;
    padding-left: 30px;
    padding-top: 89px;
    /* box-shadow: 0px 8px 8px -4px var(--matterColorNegative); */
    overflow-x: hidden;
    padding-bottom: 20px;
  }
}

.showPanel {
  display: block;
}

.hidePanel {
  display: none;
}

.filterInner {
  border-right: 1px solid var(--matterColorNegative);
  padding-right: 30px;
  max-width: 840px;
}

.scrollLayerPanel {
  background: transparent;
}

.select {
  width: 100%;
  max-width: 810px;
}

.statesList {
  max-width: 810px;
  padding-top: 30px;
  column-gap: 30px;
  row-gap: 10px;


  @media (--viewportMedium) {
    column-count: 3;
  }
}

.statesItem {
  position: relative;
}

.subjectItem {
  display: none;
}

.subjectItemOpen {
  display: block;
}

.subjectsItem {
  display: flex;
  flex-direction: column;
}

.subjectsParent {
  font-weight: 700;
  cursor: pointer;
}

.input {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;

  /* Highlight the borders if the checkbox is hovered, focused or checked */
  &:hover + label .box,
  &:focus + label .box,
  &:checked + label .box {
     stroke: var(--marketplaceColor);
   }

  /* successColor version */
  &:hover + label .boxSuccess,
  &:focus + label .boxSuccess,
  &:checked + label .boxSuccess {
     stroke: var(--successColor);
   }

  /* Display the "check" when checked */
  &:checked + label .checked {
     display: inline;
     stroke: var(--marketplaceColor);
     stroke-width: 1px;
   }

  /* Display the "check" when checked */
  &:checked + label .checkedSuccess {
     display: inline;
     stroke: var(--successColor);
     stroke-width: 1px;
   }

  /* Hightlight the text on checked, hover and focus */
  &:focus + label .text,
  &:hover + label .text,
  &:checked + label .text {
     color: var(--matterColorDark);
   }
}

.label {
  display: flex;
  align-items: center;
  padding: 0;
}

.checkboxWrapper {
  height: 32px;
  margin-top: -1px;
  margin-right: 12px;
  align-self: baseline;

  display: inline-flex;
  align-items: center;
  cursor: pointer;

  @media (--viewportMedium) {
    height: 24px;
  }
}

.checked {
  & g {
      display: block;
    }
}


.unChecked {
  display: none;
  fill: var(--marketplaceColor);
}

.checkedSuccess {
  display: inline;
  stroke: var(--successColor);
  stroke-width: 1px;
}

.boxSuccess,
.box {
  stroke: var(--matterColorAnti);
}

.text {
}
.textRoot {
  @apply --marketplaceListingAttributeFontStyles;
  color: var(--matterColor);
  margin-top: -1px;
  margin-bottom: 1px;
  cursor: pointer;
}

.sectionsTitle {
  color: var(--successColor);
  padding: 20px 0 10px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

.titleSectionsHolder {
  display: flex;
  justify-content: flex-end;
}

.selectedFiltersList {
  display: flex;
  flex-wrap: wrap;
}

.selectedFiltersItem {
  line-height: 20px;
  height: 36px;
  background: #EFCA80;
  border-radius: 4px;
  color: #454545;
  font-size: 16px;
  font-weight: 700;
  padding: 0 12px;
  margin: 8px 8px 8px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
