.additionalIOrdersInfo {
  display: block;
  padding: 0 24px 0;

  @media (--viewportMedium) {
    padding: 0;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.listJobs {
  border-top: 1px solid var(--colorLightGrey);
  margin-top: 30px;
  padding-top: 30px;
}


.listBig {
  line-height: 20px;
  /* padding-bottom: 29px; */
  margin-bottom: 40px;
}

.item {
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
}

.itemMobile {

  @media (--viewportLarge) {
    display: none;
  }
}

.itemBig {
  margin-bottom: 7px;
}

.itemSpan {
  &:after {
      content: ', ';
    }

  &:last-child {
  &:after {
      content: '';
    }
  }
}

.subItem {
  font-size: 16px;
  font-weight: 400;
}

.subItemLink {
  color: var(--marketplaceColor);
  cursor: pointer;
}

.subItemCup {
  text-transform: capitalize;
}

.modalTitle {
  display: flex;
  justify-content: center;
}

.daysList {
  /*padding: 20px 0 19px 15px;*/
}

.daysItem {
  display: flex;
  width: 100%;
  position: relative;
  border-bottom: 1px solid var(--colorLightGrey);
  padding: 20px 0 19px 0;

  @media (--viewportMedium) {
    padding: 20px 0 19px 15px;
  }
}

.daysItemHoliday {
  background: var(--lightFailColor);
}

.daysSpan {
  flex: 0 0 25%;
  max-width: 25%;
  font-size: 14px;

  @media (--viewportMedium) {
    font-size: 16px;
  }
}

.tableHeaderList {
  display: flex;
  width: 100%;
  border-bottom: 1px solid var(--colorLightGrey);
  padding: 20px 0 19px 0;
  margin: 0;

  @media (--viewportMedium) {
    padding: 20px 0 19px 15px;
  }
}

.tableHeaderItem {
  flex: 0 0 10%;
  max-width: 10%;
  font-size: 14px;
  color: var(--matterColorAnti);

  @media (--viewportMedium) {
    font-size: 16px;
  }
}

.tableHeaderItem1, .daysSpan1 {
  flex: 0 0 22%;
  max-width: 22%;
}

.tableHeaderItem2, .daysSpan2 {
  flex: 0 0 25%;
  max-width: 25%;

  @media (--viewportMedium) {
    flex: 0 0 28%;
    max-width: 28%;
  }
}

.tableHeaderItem3, .daysSpan3 {
  flex: 0 0 39%;
  max-width: 39%;

  @media (--viewportMedium) {
    flex: 0 0 40%;
    max-width: 40%;
  }
}

.customPeriodsList {

}

.customPeriodsItem {
  display: flex;
}

.customPeriodsDay {
  padding-right: 3px;
  flex: 0 0 90px;
  max-width: 90px;
}

.customPeriodsTime {
  display: flex;
  flex-direction: column;
  padding-left: 3px;
 }


