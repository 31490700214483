.input {
  border-color: var(--matterColor);
}

.inputSuccess {
  border-color: var(--successColor);
}

.inputError {
  border-color: var(--failColor);
}
