@import '../../../styles/propertySets.css';

/* Desktop */
.root {
  /* Size */
  width: 100%;
  height: var(--topbarHeightDesktop);

  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* align-items: flex-start; */
  align-items: center;
  padding: 0 12px 0 0;

  /* fill */
  background-color: var(--matterColorLight);

  /* shadows */
  box-shadow: var(--boxShadowLight);

  @media (--viewportMLarge) {
    padding: 0 33px 0 0;
  }
}

/* logo */
.logoLink {
  /* @apply --TopbarDesktop_linkHover; */
  flex-shrink: 0;
  padding: 16px 24px;
  height: 100%;

  @media (--viewportLarge) {
    padding: 16px 24px;
  }

  @media (--viewportMLarge) {
    padding: 16px 50px;
  }
}

.logo {
  /* If height is increased, change link paddings accordingly */
  /* height: 27px; */

  /* Good aspect ratio should be less than 160/27 (width/height) */
  /* NOTE: extremely wide logos, need special handling. */
  width: auto;
  max-width: 220px;
  object-fit: contain;
  object-position: left center;

  @media (--viewportMedium) {
    max-width: 100px;
  }

  @media (--viewportMLarge) {
    max-width: 220px;
  }
}

/* Search */

.searchHolder {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;

  @media (--viewportLarge) {
    width: 30%;
  }

  @media (min-width: 1440px) {
    width: 35%;
  }
}

.searchLink {
  flex-grow: 1;
  height: 100%;
  border-left: 1px solid var(--matterColorNegative);

  @media (--viewportLarge) {
    padding-left: 0;
    margin-right: 33px;
  }

  &:last-of-type {
    border-right: 1px solid var(--matterColorNegative);
    margin-right: 7px;
  }
}

.search {
  @apply --marketplaceH4FontStyles;
  @apply --TopbarDesktop_label;
  color: var(--matterColor);
}

.topbarSearchWithLeftPadding {
  padding-left: 24px;
  height: var(--topbarHeightDesktop);

  @media (--viewportLarge) {
    padding-left: 34px;
  }
}

/* Create listing (CTA for providers) */
.createListingLink {
  /* @apply --TopbarDesktop_linkHover; */
  @apply --marketplaceMessageFontStyles;
  font-size: 14px;

  flex-shrink: 0;
  height: 100%;
  padding: 0 10px;
  color: var(--marketplaceColor);
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  align-items: center;

  &:hover {
    color: var(--marketplaceColorDark);
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }

  @media (--viewportLarge) {
    padding: 0 10px;
  }

  &.createListingLinkRegular {
    color: var(--matterColorDark);

    &:hover {
      color: var(--marketplaceColorDark);
    }
  }
}

.createListing {
  @apply --TopbarDesktop_label;
}

.createListing {
  @apply --TopbarDesktop_label;
}

/* Inbox */
.inboxLink {
  /* @apply --TopbarDesktop_linkHover; */
  @apply --marketplaceMessageFontStyles;
  height: 100%;
  padding: 0 12px;
  margin-top: 0;
  margin-bottom: 0;
  color: var(--matterColor);

  &:hover {
    color: var(--matterColorDark);
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }

  @media (--viewportLarge) {
    padding: 0 17px 0 27px;
  }
}

.inbox {
  @apply --TopbarDesktop_label;
  position: relative;
}

.notificationDot {
  /* Dimensions */
  width: 12px;
  height: 12px;
  border-radius: 50%;

  /* Position */
  position: absolute;
  top: -4px;
  right: -4px;

  /* Style: red dot */
  background-color: var(--failColor);

  animation-name: notificationPop;
  animation-duration: 0.1s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;

  animation-timing-function: ease-in;
  opacity: 0;
  transform: scale(0);
}

@keyframes notificationPop {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Profile menu */
.profileMenuLabel {
  /* @apply --TopbarDesktop_linkHover; */
  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0;
  padding: 0 12px;

  &:active {
    border-bottom: 0;
  }

  @media (--viewportLarge) {
    padding: 0 17px;
  }
}

.profileMenuIsOpen {
  &:hover {
    border-bottom: 0;
  }
}

.avatar {
  margin: 16px 0;
}

.profileMenuContent {
  min-width: 276px;
  padding-top: 20px;
}

/* Authentication */
.signupLink {
  color: var(--matterColorLight);
  background: var(--failColor);
  height: 48px;
  padding: 12px 14px;
  border-radius: var(--borderRadius);
  font-size: 16px;
  align-items: center;
  justify-content: center;
  display: flex;
  min-width: 90px;

  &:hover,
  &:active,
  &:focus {
     background-color: var(--failColorDark);
     color: var(--matterColorLight);
     cursor: pointer;
   }
}

.loginLink {
  /* @apply --TopbarDesktop_linkHover; */
  flex-shrink: 0;
  height: 100%;
  padding: 0 17px;

  color: var(--matterColor);

  &:hover {
    color: var(--matterColorDark);
  }
}

.signup,
.login {
  @apply --TopbarDesktop_label;
  @apply --marketplaceMessageFontStyles;
}

/* left animated "border" like hover element */
.menuItemBorder {
  position: absolute;
  top: 2px;
  left: 0px;
  height: calc(100% - 4px);
  width: 0;
  transition: width var(--transitionStyleButton);
}

.profileSettingsLink,
.yourListingsLink {
  @apply --marketplaceH4FontStyles;
  position: relative;
  display: block;

  /* Dimensions */
  width: 100%;
  margin: 0;
  padding: 4px 24px;

  /* Layout details */
  color: var(--matterColor);
  text-align: left;
  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--matterColorDark);
    text-decoration: none;

    & .menuItemBorder {
      width: 6px;
      background-color: var(--marketplaceColor);
    }
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.logoutButton {
  @apply --marketplaceLinkStyles;
  /* Font is specific to this component */
  @apply --marketplaceH5FontStyles;
  font-size: 14px;

  /* Dimensions */
  position: relative;
  width: 100%;
  min-width: 276px;
  margin: 0;
  padding: 20px 24px;

  /* Layout details */
  color: var(--matterColorAnti);
  text-align: left;
  white-space: nowrap;
  transition: var(--transitionStyleButton);
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: var(--matterColorNegative);

  &:hover {
    color: var(--matterColorDark);
    text-decoration: none;

    & .menuItemBorder {
      height: 100%;
      width: 6px;
      top: 0;
      background-color: var(--marketplaceColor);
    }
  }

  @media (--viewportMedium) {
    margin-top: 20px;
    margin-bottom: 0;
  }
}

.currentPage {
  color: var(--matterColorDark);
  position: relative;

  & .menuItemBorder {
    width: 6px;
    background-color: var(--matterColorDark);
  }

  &:hover {
    & .menuItemBorder {
      background-color: var(--matterColorDark);
    }
  }
}

.desktopMenu {
  display: none;

  @media (--viewportMLarge) {
    display: flex;
    flex-shrink: 0;
    height: 100%;
  }
}

.tabletMenu {
  display: block;
  padding: 0 12px;

  @media (--viewportLarge) {
    padding: 0 17px;
  }

  @media (--viewportMLarge) {
    display: none;
  }
}

.tabletMenuOpen {

}

.tabletMenuContent {
  right: 0;
  min-width: 200px;

  & > div {
    position: absolute;
    right: 24px;
  }

  & > ul {
    padding: 12px 0;
  }

  & .createListing {
    margin: 10px 0;
  }
}

.becomeaTeacherLink {
  color: var(--matterColorLight);
  background: var(--failColor);
  height: 40px;
  padding: 0 12px;
  border-radius: var(--borderRadius);
  font-size: 16px;
  align-items: center;
  justify-content: center;
  display: flex;
  width: 100%;
  max-width: 240px;
  margin: 20px 12px;

  &:hover,
  &:active,
  &:focus {
     background-color: var(--failColorDark);
     color: var(--matterColorLight);
   }
}

.topbarNavLinksContainer {
  flex-wrap: wrap;
  flex-grow: 1;
  display: none;

  @media (--viewportLarge) {
    display: flex;
  }
}
