@import '../../styles/propertySets.css';

/* Order form inside modalContainer needs special handling */
/* since "inMobile" breakpoint is actually --viewportLarge */
.modalContainer {
  @apply --marketplaceModalInMobileBaseStyles;

  height: 100vh;

  @media (--viewportMedium) {
    flex-basis: 576px;
    height: unset;
    padding: var(--modalPaddingMedium);
    background-color: var(--matterColorLight);
    margin-top: 12.5vh;
    margin-bottom: 12.5vh;
  }

  @media (--viewportLarge) {
    padding: 0;
    background-color: transparent;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.modalHeading {
  margin-top: 89px;
  margin-bottom: 36px;
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.title {
  /* Font */
  @apply --marketplaceH1FontStyles;
  color: var(--matterColor);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 5px;
  }
}

.price {
  @apply --marketplaceH2FontStyles;
  color: var(--marketplaceColor);
  margin: 0 24px;


  @media (--viewportMedium) {
    margin: 0;
    font-size: 30px;
    font-weight: 600;
  }
}

.priceTip {
  color: var(--matterColorMiddle);
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
}

.author {
  display: flex;
  flex-direction: row;

  @apply --marketplaceH4FontStyles;
  margin: 24px;
  padding: 0 0 32px;
  border-bottom: 1px solid var(--colorLightGrey);


  @media (--viewportMedium) {
    width: 100%;
    margin: 24px 0 32px 0;
    color: var(--matterColor);
  }
}

.providerAvatar {
  margin-right: 10px;
}

.orderHeading {
  display: none;

  @media (--viewportLarge) {
    display: block;
    margin-bottom: 0;
  }
}

.orderTitle {
  /* Font */
  color: var(--matterColorMiddle);

  margin-top: 0;
  font-size: 32px;

}

.orderHelp {
  display: none;
  @apply --marketplaceH5FontStyles;

  @media (--viewportMedium) {
    color: var(--matterColor);
    display: block;
    margin-top: 0;
  }
}

.bookingForm {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0;

  @media (--viewportMedium) {
    padding: 0;
    min-height: 400px;
  }

  @media (--viewportLarge) {
    min-width: 312px;
    min-height: auto;
  }
}

.bookingDatesSubmitButtonWrapper {
  flex-shrink: 0;
  padding: 0 24px 24px 24px;
  width: 100%;
  position: fixed;
  bottom: 0;
  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    padding: 0;
    width: 100%;
    position: static;
    bottom: unset;
    background-color: transparent;
  }
}

.openOrderForm {
  /* Ensure that mobile button is over Footer too */
  z-index: 9;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 18px 24px 18px 16px;
  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadowTop);
  display: flex;

  /* Contain repainting to this component only */
  /* 3D painting container helps scrolling */
  transform: translate3d(0, 0, 0);

  @media (--viewportMedium) {
    padding: 18px 60px 18px 60px;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.openOrderFormUnlogged {
  bottom: 50px;
}

.priceContainer {
  /* Layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  margin-right: 22px;
  padding: 5px 12px;
}

.priceValue {
  /* Font */
  @apply --marketplaceH2FontStyles;
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 0px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnit {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 0px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.orderButton {
  @apply --marketplaceButtonStylesPrimary;
  color: var(--matterColorLight);
  background: var(--marketplaceColor);
  margin: 0 24px;

  /* Clear padding that is set for link elements looking like buttons */
  padding: 0;
}

.closedListingButton {
  border-left: 1px solid var(--matterColorNegative);
  width: 100%;
  padding: 15px 24px 15px 24px;
  text-align: center;
}

.receipt {
  flex-shrink: 0;
}

.list {
  color: var(--matterColorMiddle);
  padding-bottom: 0;
  margin: 10px 24px 0;


  @media (--viewportMedium) {
    margin-left: 0;
    margin-right: 0;
  }
}

.listBig {
  line-height: 20px;
  border-bottom: 1px solid var(--colorLightGrey);
  padding-bottom: 29px;
  margin-bottom: 40px;
}

.item {
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
  margin: 12px 24px 0;

  @media (--viewportMedium) {
    margin: 12px 0 0;
  }
}

.itemBig {
  margin-bottom: 7px;
}

.itemSpan {
  &:after {
     content: ', ';
   }

  &:last-child {
  &:after {
     content: '';
   }
  }
}

.subItem {
  font-size: 16px;
  font-weight: 400;
}

.subItemCup {
  text-transform: capitalize;
}

.button {
  color: var(--matterColorLight);
  background: var(--marketplaceColor);
}

.orderPanelInfo {
  padding: 0 24px;
  border-bottom: 1px solid var(--colorLightGrey);
  margin-bottom: 40px;

  & ul {
    margin-bottom: 36px;
  }

  @media (--viewportMedium) {
    padding: 0;
  }
}

.enquiryModalContent {
  flex-grow: 1;
  display: flex;
}

.enquiryForm {
  flex-grow: 1;
  margin: 70px 0 100px 0;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    min-height: 400px;
    margin: 0;
  }
}

.enquirySubmitButtonWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;
  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    position: static;
    padding: 0;
    margin-top: 60px;
  }
}
