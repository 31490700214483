@import '../../../styles/propertySets.css';

.mobileInputRoot {
  width: 100%;
  border-radius: 6px;
  margin-bottom: 10px;
  border: 1px solid var(--matterColorAnti);
}

.desktopInputRoot {
  height: var(--topbarHeightDesktop);
}

.mobileIcon {
  @apply --marketplaceButtonStyles;
  border-radius: 6px 0 0 6px;
  min-height: 58px;
  width: 58px;
  flex-shrink: 0;

  display: flex;
  align-items: center;

  & path,
  & circle {
    stroke: var(--matterColorLight)
  }
}

.mobileInput {
  flex-grow: 1;
  @apply --marketplaceMessageFontStyles;

  /* Font */
  /* @apply --marketplaceH1FontStyles; */

  /* Layout */
  margin: 0;
  /* padding: 4px 13px 10px 13px; */
  /* height: var(--TopbarSearchForm_inputHeight); */
  /* line-height: unset; */

  /* Borders */
  border-radius: 0 6px 6px 0;
  border: none;
  padding-left: 10px;

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }

  /* &:hover,
  &:focus {
    border-color: var(--marketplaceColor);
    outline: none;
  } */
}

.desktopIcon {
  height: var(--topbarHeightDesktop);
  border: none;
  padding-top: 3px;

  display: flex;
  width: 24px;
  align-self: stretch;
  background-color: var(--matterColorLight);
}

.desktopInput {
  flex-grow: 1;
  /* Font */
  @apply --marketplaceMessageFontStyles;
  /* height: var(--topbarHeightDesktop); */
  line-height: unset;
  border: none;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 10px;

  /* Search text gets ellipsis if it's too wide */
  text-overflow: ellipsis;
  overflow-x: hidden;

  &:hover,
  &:focus {
    border-color: var(--marketplaceColor);
    outline: none;
  }

  &:hover::placeholder,
  &:focus::placeholder {
    color: var(--matterColor);
  }

  /* Placeholder text might be too wide */
  &::placeholder {
    text-overflow: ellipsis;
    overflow-x: hidden;
    font-weight: var(--fontWeightMedium);
    transition: var(--transitionStyleButton);
  }

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    padding-right: 10px;
    font-size: 14px;
  }

  @media (min-width: 1440px) {
    padding-right: 20px;
    font-size: 16px;
  }
}

.mobilePredictions {
  position: absolute;
  top: calc(var(--TopbarSearchForm_inputHeight) - var(--TopbarSearchForm_bottomBorder));
  left: 0;
  min-height: calc(
    100vh -
      calc(
        var(--TopbarSearchForm_topbarMargin) + var(--TopbarSearchForm_inputHeight) -
          var(--TopbarSearchForm_bottomBorder)
      )
  );
  width: 100%;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);
}

.mobilePredictionsAttribution {
  /* When using the Google Maps Places geocoder, the "Powered by Google"
   text is hidden in Mobile Safari without giving some extra space to
   it. */
  margin-bottom: 100px;
}

.desktopPredictions {
  position: absolute;
  width: 100%;
  top: 50px;
  left: 0;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);

  margin-top: calc(
    var(--topbarHeightDesktop) - var(--TopbarSearchForm_inputHeight) +
      var(--TopbarSearchForm_bottomBorder)
  );
  max-width: 434px;
}

/**
 * Keyword search
 */
.keywordSearchWrapper {
  position: relative;
  display: flex;
}

.searchSubmit {
  border: 0;
  padding: 0;
  cursor: pointer;

  &:hover,
  &:focus {
    outline: none;

    & .iconSvgGroup {
      stroke: var(--matterColor);
    }
  }
}

.icon {
  display: flex;
  width: 24px;
  align-self: stretch;
  border-bottom: 1px solid var(--marketplaceColor);
  background-color: var(--matterColorLight);
}

.iconSvg {
  margin: auto;
  fill: none;
}

.iconSvgGroup {
  stroke: var(--marketplaceColor);
  stroke-width: 2.5px;

  @media (--viewportMedium) {
    stroke-width: 2px;
  }
}

form.newSearchDesign {
  
  display: flex;
  align-items: center;
  margin: 0 0;
  border-left: none;
  width: 100%;
  /* max-width: 500px; */
  
  &:last-of-type {
    border-right: none;
  }

  & .keywordSearchWrapper {
    display: flex;
    height: 48px;
    width: 100%;
    /* max-width: 500px; */
    border: 1px solid var(--matterColorAnti);
    border-right: 0;
    border-radius: 6px 0 0 6px;
    padding-left: 0;
    overflow: hidden;
    width: calc(100% - 140px);
    min-width: 130px;

    @media (--viewportLarge) {
      width: calc(100% - 100px);
    }
    
    @media (min-width: 1440px) {
      width: calc(100% - 200px);
    }
  }

  & .searchSubmit {
    background-color: var(--marketplaceColor);
  }

  & .desktopIcon {
    width: 40px;
    border-radius: 8px;
    padding: 0;
    background-color: transparent;

    & path,
    & circle {
      stroke: var(--matterColorLight);
    }
  }
}

.topbarRoleSelectContainer {
  min-width: 140px;
  border: 1px solid var(--matterColorAnti);
  border-radius: 6px;
  height: 48px;
  
  @media (--viewportMedium) {
    border-radius: 0 6px 6px 0;
    /* min-width: 100px; */
  }

  @media (--viewportMLarge) {
    min-width: 100px;
  }

  @media (min-width: 1440px) {
    min-width: 200px;
  }
}

.topbarRoleSelect {
  text-align: left;
  padding: 10px 25px 10px 20px;
  
  @media (--viewportMedium) {
    padding: 10px 25px 10px 10px;
    font-size: 14px;
  }

  @media (min-width: 1440px) {
    padding: 10px 25px 10px 20px;
    font-size: 16px;
  }
}

.topbarRoleSelectContent {
  margin-top: 10px;
  border: 1px solid var(--matterColorAnti);
  padding: 7px 9px;
  border-radius: 6px;
  box-shadow: none;

  & ul {
    min-width: 150px;
  }
}

.extraLabel {
  display: block;
  font-size: 12px;
}

.topbarRoleSelectItem {
  padding: 6px 10px;
  cursor: pointer;

  &:hover, &:focus {
    background-color: var(--marketplaceColorExtraLight);
  }
}

.keywordSearchIcon {
  padding: 10px 0 10px 20px;

  & path, & circle {
    stroke: var(--matterColorAnti);
  }

  @media (--viewportMedium) {
    padding-left: 10px;
  }

  @media (min-width: 1440px) {
    padding-left: 20px;
  }
}