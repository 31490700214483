@import '../../../styles/propertySets.css';

.root {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 24px;
}

.searchOptions {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.searchOptionsTitle {
  font-size: 32px;
  line-height: 1;
  margin: 0;
}

.searchResultSummary {
  @apply --marketplaceH4FontStyles;
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 20px;
  width: 70%;
  margin: 0;
}

.loadingResults {
  @apply --marketplaceH4FontStyles;
  line-height: 20px;

  /* Cover parent element's space*/
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;

  /* Layout */
  padding: 9px 0 0 0;
  margin: 0;
}

.resultsFound {
  white-space: nowrap;
  font-size: 18px;
  line-height: 20px;
}

.sortyByWrapper {
  display: inline-flex;
  margin-left: auto;
}

.sortyBy {
  @apply --marketplaceSmallFontStyles;
  font-weight: var(--fontWeightRegular);
  padding: 8px 8px 8px 0;
}

.selectedFiltersHolder {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.selectedFiltersTitle {
  font-size: 18px;
}

.selectedFiltersList {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0;
}

.selectedFiltersItem {
  position: relative;
  align-items: center;
  justify-content: stretch;
  display: flex;
  line-height: 20px;
  height: 36px;
  background: rgb(239, 202, 128);
  border-radius: 4px;
  color: rgb(69, 69, 69);
  font-size: 16px;
  font-weight: 700;
  padding: 0 30px 0 12px;
  margin: 0 8px 8px 0;
  text-transform: capitalize;
}

.closeIcon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}

.resetAllFiltersButton {
  flex: 0 0 110px;
  max-width: 110px;
  margin-left: 15px;
  cursor: pointer;
  font-size: 16px;
  line-height: 20px;
  height: 36px;
  color: var(--successColor);
}
