@import '../../../styles/propertySets.css';

.root {
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.sectionContainer {
  padding: 0;
  margin-bottom: 30px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 30px;
  }
}

.licenseContainer {
  margin-bottom: 10px;
  position: relative;
  padding: 20px 0 40px;
  border-bottom: 1px solid var(--matterColorAnti);
}

.licensesContainer {
  margin-bottom: 56px;
}

.btnHolder {
  display: flex;
  justify-content: flex-end;
}

.actionBtn {
  border: none;
  cursor: pointer;
}

.addBtn {
  color: var(--marketplaceColor)
}

.removeBtn {
  color: var(--failColor)
}

.sectionTitle {
  /* Font */
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 12px;
  padding-top: 3px;
  padding-bottom: 3px;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 24px;
    padding: 6px 0 2px 0;
  }
}

.sectionTitleAbs {
  position: absolute;
  padding-top: 20px;
}

.sectionTitleCustom {
  margin-bottom: -20px;
  margin-top: 43px;
}

.lastSection {
  margin-bottom: 69px;

  @media (--viewportMedium) {
    margin-bottom: 111px;

    & .sectionTitle {
      margin-bottom: 16px;
    }
  }
}

.uploadAvatarInput {
  display: none;
}

.uploadAvatarWrapper {
  margin-top: 18px;
  margin-bottom: 18px;

  @media (--viewportMedium) {
    margin-top: 24px;
    margin-bottom: 16px;
  }
}

.label {
  width: var(--ProfileSettingsForm_avatarSize);
  @media (--viewportMedium) {
    width: var(--ProfileSettingsForm_avatarSizeDesktop);
  }
}

.avatarPlaceholder,
.avatarContainer {
  /* Dimension */
  position: relative;
  width: var(--ProfileSettingsForm_avatarSize);
  height: var(--ProfileSettingsForm_avatarSize);

  /* Center content */
  display: flex;
  align-items: center;
  justify-content: center;

  /* Initial coloring */
  background-color: var(--matterColorBright);
  border-radius: calc(var(--ProfileSettingsForm_avatarSize) / 2);
  cursor: pointer;

  @media (--viewportMedium) {
    width: var(--ProfileSettingsForm_avatarSizeDesktop);
    height: var(--ProfileSettingsForm_avatarSizeDesktop);
    border-radius: calc(var(--ProfileSettingsForm_avatarSizeDesktop) / 2);
  }
}

.avatarPlaceholder {
  /* Placeholder border */
  border-style: dashed;
  border-color: var(--matterColorNegative);
  border-width: 2px;

  transition: var(--transitionStyleButton);

  &:hover {
    border-color: var(--matterColorAnti);
  }
}

.avatarPlaceholderTextMobile {
  @media (--viewportMedium) {
    display: none;
  }
}
.avatarPlaceholderText {
  display: none;

  @media (--viewportMedium) {
    display: block;
    max-width: 130px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }
}

.avatarUploadError {
  /* Placeholder border */
  border-style: dashed;
  border-color: var(--failColor);
  border-width: 2px;
}

.error {
  /* Font */
  @apply --marketplaceH4FontStyles;
  color: var(--failColor);
  margin-top: 18px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 22px;
    margin-bottom: 2px;
  }
}

.rootAvatar {
  width: 100%;
  height: 100%;
  font-family: 'Lato', Helvetica, Arial, sans-serif;
  border-radius: 50%;

  /* Position possible initials to the center of the component */
  display: flex;
  align-items: center;
  justify-content: center;

  /* Colors */
  background: var(--marketplaceColor);
  color: var(--matterColorLight);

  &:hover {
     text-decoration: none;
   }
}

.avatar {
  width: 100%;
  height: 100%;
}

.changeAvatar {
  /* Font */
  @apply --marketplaceH5FontStyles;

  font-weight: var(--fontWeightMedium);

  /* Positioning: right */
  position: absolute;
  bottom: 27px;
  right: -129px;
  /* Dimensions */
  width: 105px;
  height: 41px;
  padding: 11px 10px 7px 35px;

  /* Look and feel (buttonish) */
  background-color: var(--matterColorLight);
  background-image: url('data:image/svg+xml;utf8,<svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"><g stroke="%234A4A4A" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><path d="M5.307 11.155L1 13l1.846-4.308L10.54 1 13 3.46zM11 5L9 3M5 11L3 9"/></g></svg>');
  background-repeat: no-repeat;
  background-position: 15px 12px;
  border: solid 1px var(--matterColorNegative);
  border-radius: 2px;

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    /* Position: under */
    bottom: -10px;
    right: auto;
    margin-top: 0;
    margin-bottom: 0;
    transition: var(--transitionStyleButton);
    padding: 11px 10px 7px 35px;
  }

  &:hover {
    border: solid 1px var(--matterColorAnti);
  }
}

.uploadingImage {
  /* Dimensions */
  width: var(--ProfileSettingsForm_avatarSize);
  height: var(--ProfileSettingsForm_avatarSize);

  /* Image fitted to container */
  object-fit: cover;
  background-color: var(--matterColorNegative); /* Loading BG color */
  border-radius: calc(var(--ProfileSettingsForm_avatarSize) / 2);
  overflow: hidden;

  display: block;
  position: relative;
  margin: 0;

  @media (--viewportMedium) {
    width: var(--ProfileSettingsForm_avatarSizeDesktop);
    height: var(--ProfileSettingsForm_avatarSizeDesktop);
    border-radius: calc(var(--ProfileSettingsForm_avatarSizeDesktop) / 2);
  }
}

.uploadingImageOverlay {
  /* Cover everything (overlay) */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  /* Overlay style */
  background-color: var(--matterColorLight);
  opacity: 0.8;

  /* Center content */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Avatar has square aspect ratio */
/* Default is 3:2 */
.squareAspectRatio {
  padding-bottom: 100%;
}

.avatarInvisible {
  visibility: hidden;
  position: absolute;
  top: -1000px;
  left: -1000px;
}

.tip {
  @apply --marketplaceDefaultFontStyles;
  color: var(--matterColorAnti);
  margin-top: 0;
  margin-bottom: 12px;
  padding-top: 5px;
  padding-bottom: 1px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 4px;
    padding-bottom: 4px;
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
  }
}

.videoTipHolder {
  margin-top: 20px;
}

.videoTip {
  font-weight: var(--fontWeightRegular);
  line-height: 20px;
  display: block;
  margin: 0;
  padding: 0;
  font-size: 14px;
  color: var(--matterColorAnti);
}

.fileInfo {
  @apply --marketplaceH5FontStyles;
  color: var(--matterColorAnti);
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 5px;
  padding-bottom: 1px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    padding: 4px 0 4px 0;
  }
}

.nameContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;

  @media (--viewportMedium) {
    margin-top: 24px;
  }
}

.requiredLabel {
  & label:after {
      content: '*';
      color: var(--failColor);
      font-size: 20px;

      @media (--viewportMedium) {
        content: '(required)';
        font-size: 14px;
      }

    }
}

.optionalLabel {
  & label:after {
      content: '(optional)';
      color: var(--matterColorAnti);
    }
}

.optionalPart {
  &:after {
    content: ' (optional) ';
    color: var(--matterColorAnti);
  }
}

.firstName {
  width: calc(50% - 9px);

  @media (--viewportMedium) {
    width: calc(34% - 9px);
  }
}

.lastName {
  width: calc(50% - 9px);

  @media (--viewportMedium) {
    width: calc(66% - 9px);
  }
}

.selectUsState {
  width: calc(50% - 9px);
}

.bioInfo {
  color: var(--matterColorAnti);
  margin-top: 11px;

  @media (--viewportMedium) {
    margin-top: 16px;
  }
}

.submitButton {
  margin-top: 24px;
  margin-bottom: 56px;
  max-width: 240px;
}

.option {
  padding: 8px 20px;
  font-size: 16px;
  font-weight: 600;

  &:hover {
     background: var(--attentionColorNotActive);
     cursor: pointer;
   }
}

.popularAreaTitle {
  font-size: 16px;
  font-weight: 700;
}

.popularAreaList {
  display: flex;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;
}

.popularAreaItem {
  background: var(--attentionColorNotActive);
  border-radius: 4px;
  margin: 5px;
  padding: 0 10px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
}

.priceHolder {
  display: flex;
  margin-bottom: 24px;
  margin-top: 6px;
  max-width: 100%;
  flex-direction: column;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 32px;
    flex-direction: row;
    align-items: center;
  }
}

.suggestedPriceHolder {
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;

  @media (--viewportMedium) {
    flex-wrap: nowrap;
  }
}

.suggestedPriceLabel {
  background: var(--attentionColorNotActive);
  border-radius: 4px;
  font-size: 16px;
  font-weight: var(--fontWeightBold);
  line-height: 20px;
  padding: 8px 12px;
  cursor: pointer;

  @media (--viewportMedium) {
    margin-right: 10px;
  }
}

.suggestedPriceLabelActive {
  background: var(--customYellow);
}

.suggestedPriceSeparator {
  color: var(--matterColorAnti);
  margin: 10px 0;
  font-size: 16px;
  font-weight: var(--fontWeightRegular);
  line-height: 20px;

  @media (--viewportMedium) {
    margin: 0 10px 0 0;
  }
}

