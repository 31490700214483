@import '../../styles/propertySets.css';

.root {
  /* Layout */
  /*display: flex;*/
  /*flex-direction: column;*/
  color: var(--matterColor);

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}

.aspectRatioWrapper {
  transition: var(--transitionStyleButton);
  /*background: var(--matterColorNegative); !* Loading BG color *!*/
  border-radius: 8px;
  height: 280px;
  overflow: hidden;

  @media (--viewportMedium) {
    &:hover {
      /* transform: scale(1.02); */
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.rootForImage {
  border: solid 1px var(--matterColorNegative);
  border-radius: 8px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.info {
  /* Layout */
  display: flex;
  flex-direction: column;
  padding: 6px 0 0 0;
  height: 100%;
}

.imageContainer {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 100%;
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: auto;
}


.title {
  /* Font */
  @apply --marketplaceDefaultFontStyles;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;
  text-transform: capitalize;
}

.areasCertified {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0;
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 5px;

  @media (--viewportMedium) {
    margin-bottom: 8px;
  }
}

.areasCertifiedTitle {
  margin-right: 4px;
  font-weight: var(--fontWeightBold);
}

.itemParent {
  font-weight: 700;

  &:after {
     content: ', ';
   }

  &:last-child {
  &:after {
     content: '';
   }
  }
}

.itemParentValue {

}

.subItem {
  font-weight: 400;
}

.itemSpan {
  font-weight: 400;
  text-transform: capitalize;
  &:after {
     content: ', ';
   }

  &:last-child {
  &:after {
     content: '';
   }
  }
}

.desktopReviewRating {
  /* For aligning rating starts in the middle
   * of a paragraph line */
  display: inline-flex;
  white-space: nowrap;
}

.reviewRatingStar {
  height: 12px;
  width: 12px;
  margin-right: 2px;

  &:last-of-type {
     margin-right: 0;
   }
}

.bottomLineHolder {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rateHolder {
  color: var(--marketplaceColor);
  font-weight: 700;
}
