@import '../../styles/propertySets.css';

.root {
  display: flex;
  flex-direction: column;
  margin-top: 36px;
}

.title {
  margin: 0 24px -10px;
  color: var(--matterColor);
}

.orderBreakdownTitle {
  margin: 23px 24px 5px;
  color: var(--matterColor);
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 6px;
  margin: 0 24px;
}

.itemTotal {
  margin-top: 15px;
}

.bookingPeriodItem {

  &:last-child {
    margin-right: 10px;
    text-align: right;
  }
}

.bookingPeriodLabel {
  @apply --marketplaceH5FontStyles;
  margin: 0;
  color: var(--matterColorAnti);
  line-height: 24px;
  padding-top: 2px;
  padding-bottom: 4px;

  @media (--viewportMedium) {
    padding-top: 1px;
    padding-bottom: 7px;
  }
}

.bookingPeriodDay {
  @apply --marketplaceButtonFontStyles;
}

.bookingPeriodMonth {
  @apply --marketplaceSmallFontStyles;
  font-weight: var(--fontWeightRegular);
  font-size: 16px;
  flex: 1;
}

.divider {
  width: calc(100% - 48px);
  height: 1px;
  margin: 12px 0 5px 0;
  border: none;
  background-color: var(--matterColorAnti);

  @media (--viewportMedium) {
    margin: 24px;
  }
}

.label {
  @apply --marketplaceSmallFontStyles;
  font-weight: var(--fontWeightRegular);
  font-size: 16px;
  flex: 1;
}

.value {
  @apply --marketplaceSmallFontStyles;
  font-weight: var(--fontWeightSemiBold);
  font-size: 16px;
  margin: 0 0 0 10px;
}

.totalLabel {
  @apply --marketplaceSmallFontStyles;
  font-size: 20px;
  font-weight: var(--fontWeightRegular);
}

.totalPrice {
  @apply --marketplaceButtonFontStyles;
  margin: 0 0 0 10px;
  padding-top: 0;
  font-size: 20px;
}

.feeInfo {
  @apply --marketplaceTinyFontStyles;
  flex-shrink: 0;
  margin: 0 24px;
  color: var(--matterColorAnti);
  padding-top: 4px;
  padding-bottom: 14px;
  font-size: 14px;

  @media (--viewportMedium) {
    padding-top: 11px;
    padding-bottom: 21px;
  }
}
