@import '../../../styles/propertySets.css';

/* Animation for the hero elements */

@keyframes animationTitle {
  0% {
    visibility: hidden;
    opacity: 0;
    top: -20px;
  }
  100% {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
}

/* Shared animation rules */
.animation {
  animation-name: animationTitle;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;
  animation-delay: 0.3s;

  visibility: hidden;
  opacity: 1;
  position: relative;
}

.root {
  width: 100%;
  height: 100%;
}

.heroContent {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  /* flex-direction: column-reverse; */
  position: relative;
  padding: 0 24px;
  margin: 0 auto;
  max-width: 80%;

  @media (--viewportMedium) {
    max-width: 100%;
    padding: 60px 20px;
    min-height: 460px;
  }

  @media (--viewportLarge) {
    flex-direction: row;
    align-items: center;
    max-width: 1272px;
    padding: 60px 36px;
  }

  @media (--viewportXLarge) {
    max-width: 1200px;
  }
}

.heroContentText {
  width: 100%;
  padding-top: 40px;
  z-index: 2;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;

  @media (--viewportMedium) {
    width: auto;
    padding-top: 0;
  }
}

.heroMainTitle {
  @apply --marketplaceHeroTitleFontStyles;
  color: var(--matterColorDark);
  composes: animation;
  margin-top: 0;
  font-size: 30px;
  text-align: center;
  font-weight: 700;

  @media (--viewportSmall) {
    text-align: left;
  }

  @media (--viewportMedium) {
    font-size: 56px;
    line-height: 66px;
    min-width: 764px;
    text-align: center;
  }
}

.coloredTitle {
  color: #EFCA80;
}

.heroMainTitleFEDelay {
  animation-delay: 0s;
}

.heroSubTitle {
  @apply --marketplaceH4FontStyles;

  margin: 0 0 26px 0;
  composes: animation;
  animation-delay: 0.5s;
  color: var(--matterColorDark);
  text-align: center;
  font-weight: 400;


  @media (--viewportSmall) {
    margin: 0 0 60px 0;
  }

  @media (--viewportMedium) {
    font-size: 24px;
    line-height: 36px;
    max-width: 373px;
  }
}

.boldSubTitle {
  font-weight: 700;
}

.heroSubTitleFEDelay {
  /* animation-delay: 0.15s; */
}

.heroButton {
  @apply --marketplaceButtonStyles;
  composes: animation;
  animation-delay: 0.7s;
  margin-bottom: 20px;

  padding: 17px 0;
  min-width: 100%;

  font-size: 20px;
  line-height: 20px;
  height: 56px;
  background-color: #EFCA80;

  @media (--viewportSmall) {
    min-width: 1px;
    margin-bottom: 0;
    width: 179px;
  }

  @media (--viewportMedium) {
    min-width: 320px;
    align-items: center;
    justify-content: center;
    display: flex;
    min-width: 1px;
  }
}

.heroButtonFEDelay {
  animation-delay: 0.3s;
}

.heroButton + .heroButton {
  margin-bottom: 0;

  background: var(--customYellow);
  color: var(--matterColor);

  &:hover,
  &:focus {
    background: var(--customYellowDark);
  }
}

.heroButtonFEDelay + .heroButtonFEDelay {
  animation-delay: 0.45s;
}

.heroContentImg {
  text-align: center;
  width: 100%;
  margin: 20px auto;

  max-width: 425px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 10px;
  z-index: 1;

  @media (--viewportSmall) {
    margin: 20px auto 60px;
  }

  @media (--viewportMedium) {
    max-width: 465px;
    height: 100%;
    right: 50px;
    left: auto;
    top: 0;
    transform: translateX(0);
  }

  @media (--viewportLarge) {
    width: auto;
    height: auto;
    margin: 0;
    max-width: 465px;
  }

  & img {
    width: 100%;
  }
}

.inviteLink {
  composes: animation;
  display: block;
  text-transform: uppercase;
  margin-bottom: 30px;
  color: var(--marketplaceColor);
  cursor: pointer;
  font-size: 14px;
  font-weight: 800;
  line-height: 30px;

  &:hover,
  &:focus {
    color: var(--marketplaceColorDark);
  }
}

.inviteLinkFEDelay {
  animation-delay: 0.2s;
}

.heroTypeString {
  color: var(--marketplaceColor);
}

.resetBgTransition .gradientContainer {
  transition: none !important;
}

.gradientContainer {
  background-color: #d9ecfc;

  @media (--viewportLarge) {
    background: url("./hero.jpg") center center no-repeat;
  }
}

.mainTypedText {
  color: #fff;
}

.emailInput {
  background-color: #fff;
  width: 100%;
  padding: 17px 20px;
  margin-bottom: 10px;

  @media (--viewportSmall) {
    margin-right: 10px;
    margin-bottom: 0;
    width: 334px;
  }
}

.emailContainer {
  display: flex;
  align-items: center;
  flex-direction: column;

  @media (--viewportSmall) {
    flex-direction: row;
  }
}

.socialButtonWrapper {
  display: flex;
  max-width: 234px;
  margin-top: 14px;


  @media (--viewportMedium) {
    width: 234px;
    margin-right: 10px;
  }
}

.socialButtonsWrapper {
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.buttonIcon {
  margin-right: 10px;
}

.socialButton {
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}
