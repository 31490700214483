@import '../../styles/propertySets.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}

.aspectRatioWrapper {
  transition: var(--transitionStyleButton);
  background: var(--matterColorNegative); /* Loading BG color */
  border-radius: 8px;

  @media (--viewportMedium) {
    &:hover {
      /* transform: scale(1.02); */
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.rootForImage {
  border: solid 1px var(--matterColorNegative);
  border-radius: 8px;
  width: 100%;
  height: 100%;
}

.info {
  /* Layout */
  display: flex;
  flex-direction: column;
  padding: 12px 0 2px 0;
}

.price {
  /* Layout */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-shrink: 0;
  margin-bottom: 3px;
}

.priceValue {
  /* Font */
  @apply --marketplaceH3FontStyles;
  line-height: 20px;
  font-weight: var(--fontWeightSemiBold);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;
  display: flex;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.priceSeat {
  font-size: 14px;
  font-weight: 400;
  color: var(--matterColorMiddle);
  margin-left: 5px;
}

.perUnit {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);
  display: inline-flex;
  align-items: center;
  margin-left: 8px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  display: flex;
  flex-direction: column;
}

.title {
  /* Font */
  @apply --marketplaceDefaultFontStyles;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;
}

.description {
  color: var(--matterColor);
  word-break: break-word;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  font-weight: var(--fontWeightRegular);
}

.authorInfo {
  display: flex;
  align-items: center;
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;

  padding-top: 2px;
  padding-bottom: 0px;

  /* Remove default margins from font */
  margin-top: 0;
  text-transform: capitalize;
  
  @media (--viewportMedium) {
    margin-bottom: 18px;
  }

  & a {
    margin-right: 12px;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}
