@import '../../styles/customMediaQueries.css';
@import '../../styles/propertySets.css';

.root {
  padding-top: 40px;

  @media (--viewportSmall) {
    padding-top: 0;
  }

  @media (--viewportMedium) {
    min-width: 90%;
  }

  @media (--viewportMLarge) {
    min-width: 1134px;
  }
}

.title {
  @apply --marketplaceH1CustomFontStyles;
  margin: 0 0 40px;
  text-align: center;
  font-size: 36px;
}

.optionsList {
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (--viewportMedium) {
    flex-direction: row;
    justify-content: center;
  }
}

.optionItem {
  padding: 40px;
  border: 1px solid;
  border-radius: 6px;
  max-width: 320px;
  margin-bottom: 20px;

  @media (--viewportMedium) {
    padding: 50px;
    width: calc((100% - 80px)/3);

    &:not(:last-child) {
      margin-right: 40px;
    }
  }


  &.admin {
    border-color: #23A0EC;
    background-color: rgba(35, 160, 236, 0.06);

    & .signupLink {
      background-color: #23A0EC;
    }
  }

  &.parent {
    border-color: #EE4D68;
    background-color: rgba(238, 77, 104, 0.06);

    & .signupLink {
      background-color: #EE4D68;
    }
  }

  &.teacher {
    border-color: #EFCA80;
    background-color: rgba(239, 202, 128, 0.06);

    & .signupLink {
      background-color: #EFCA80;
    }
  }
}

.optionTitle {
  font-size: 20px;
  line-height: 1.3;
  max-width: 184px;
  margin: 0 auto 30px;
  font-weight: 700;
  text-align: center;
}

.signupLink {
  color: var(--matterColorLight);
  background: var(--failColor);
  height: 54px;
  padding: 12px 30px;
  border-radius: var(--borderRadius);
  font-size: 16px;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;

  &:hover, &:focus {
    color: #fff;
  }
}

.haveAccount {
  margin-top: 40px;
  text-align: center;
  font-weight: normal;
}
