.root {

  margin-top: 0;
  margin-bottom: 0;
  white-space: pre-line;

  & :global(.DraftEditor-editorContainer) {
    background-color: transparent;
    border: none;

    & span {
      background-color: transparent !important;
    }
  }


  & :global(.public-DraftStyleDefault-block) {
    margin: 10px 0;
    align-items: center;
    flex-wrap: wrap;
    word-break: break-word;
    white-space: normal;
  }

  & a span,
  & a {
    color: var(--marketplaceColorDark)!important;
    text-decoration: underline;

    &:hover,
    &:focus {
      color: var(--marketplaceColor)!important;
    }
  }

  & ul {
    margin: 0;
    padding-left: 30px;
    list-style: disc;
    line-height: 1;
  }

  & ol {
    padding-left: 30px;
    margin: 0;
    line-height: 1;
  }

  & p span,
  & a span,
  & li span {
    font-family: var(--fontFamily) !important;
  }
}
