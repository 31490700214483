@import '../../styles/propertySets.css';

.root {
  position: relative;
  z-index: 0;
}

.rootUnlogged {
  padding-bottom: 54px;

  @media (--viewportMedium) {
    padding-bottom: 0;
  }
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 40px 24px 16px;


  @media (--viewportMedium) {
    margin: 0 auto;
  }

  @media (--viewportLarge) {
    max-width: 1200px;
    padding: 40px 36px 16px;
  }
}

/* Mobile only social media links */
.someLiksMobile {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    display: none;
  }
}

.icon {
  display: inline-block;
  width: 20px;
  height: 24px;
  margin-right: 12px;
  text-align: center;
}

/* Footer links */
.links {
  /* Layout */
  width: 100%;
  text-align: center;

  @media (--viewportSmall) {
    text-align: left;
  }

  @media (--viewportLarge) {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-bottom: 0;
  }
}

/* Desktop: logo and organization stuff */
.organization {
  flex-shrink: 0;
  flex-basis: 185px;
  display: flex;
  margin-bottom: 24px;
  flex-direction: column;
  justify-content: space-around;

  @media (--viewportSmall) {
    margin-right: 24px;
  }

  @media (--viewportMedium) {
    order: 1;
    margin-right: 12px;
  }

  @media (--viewportLarge) {
    margin-right: 57px;
  }

  @media (--viewportLargeWithPaddings) {
    margin-right: 12px;
  }
}

.logoLink {
  display: block;
  margin-bottom: 20px;

  @media (--viewportLarge) {
    margin-bottom: 40px;
  }
}

.logo {
  object-fit: contain;
  object-position: left center;
  /* width: 100%;
  height: 27px; */
}

.organizationInfo {
}


.organizationCopyright {
  @apply --marketplaceTinyFontStyles;
}

.organizationDescription {
  margin: 0;
  font-size: 14px;
  line-height: 16px;
}

.organizationCopyright {
  margin: 0;
  padding: 20px 0;
  text-align: center;
  background: #fbfbfb;
  border-top: 1px solid var(--matterColorNegative);
}

.copyrightLink {
  color: var(--matterColorAnti);
  transition: var(--transitionStyleButton);

  font-size: 14px;
  line-height: 18px;
  font-weight: var(--fontWeightRegular);

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }
}

/* Desktop and Mobile: links to more information */

.infoLinksHolder {
  display: flex;
  order: 2;
}

.infoLinks {
  order: 2;
  flex-basis: 165px;
  margin-bottom: 24px;

  @media (--viewportSmall) {
    margin-right: 24px;
  }

  @media (--viewportMedium) {
    padding-top: 2px;
    margin-right: 12px;
  }
}

.footerListTitle {
  font-size: 14px;
  line-height: 24px;
  margin: 18px 0 0;
  font-weight: var(--fontWeightBold);
}

.list {
  margin-top: 0;
  margin-bottom: 0;
  list-style: none;
}

.listItem {
  min-height: 24px;
  margin-bottom: 0;
  line-height: 24px;
}

.link {
  /* Font */
  @apply --marketplaceH5FontStyles;
  line-height: 24px;
  color: var(--matterColor);
  transition: var(--transitionStyleButton);

  margin-top: 0;
  margin-bottom: 12px;

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

/* Desktop and Mobile: searches - first column */
.searches {
  order: 3;
  flex-basis: 165px;
  margin-bottom: 24px;

  @media (--viewportSmall) {
    margin-right: 24px;
  }

  @media (--viewportMedium) {
    padding-top: 2px;
    margin-right: 12px;
  }
}

/* Desktop and Mobile: searches - second column */
/* This is not always visible */
.searchesExtra {
  order: 4;
  flex-basis: 165px;
  margin-bottom: 24px;

  @media (--viewportSmall) {
    margin-right: 24px;
  }

  @media (--viewportMedium) {
    padding-top: 2px;
    margin-right: 12px;
  }
}

/* Desktop: extra links (social media links and legal links) */
.extraLinks {
  flex-shrink: 0;
  order: 5;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-basis: 198px;
  margin-bottom: 24px;
  padding-bottom: 2px;
  text-align: center;

  @media (--viewportSmall) {
    margin-right: 24px;
    text-align: right;
  }

  @media (--viewportMedium) {
    margin-right: 12px;
  }

  @media (--viewportLarge) {
    align-items: flex-end;
    margin-right: 0;
  }
}

.referringLink {
  @apply --marketplaceMessageFontStyles;
  margin-top: 24px;
  font-size: 14px;
  cursor: pointer;
  color: var(--marketplaceColor);

  @media (--viewportLarge) {
    margin-top: 0;
  }

  &:hover,
  &:focus {
    color: var(--marketplaceColorDark);
  }
}

.legalMatters {
  /* Get extra space from parent element (which is using flexbox) */
  flex-grow: 1;

  /* Layout */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.tosAndPrivacy {
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-bottom: 10px;
  }
}

.legalLink,
.privacy,
.terms {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);
  transition: var(--transitionStyleButton);

  margin-top: 0;
  margin-bottom: 0;

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

/* Mobile: copyright mark and legal links for mobile */
.copyrightAndTermsMobile {
  /* Positioning */
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  /* Dimensions */
  height: 60px;
  padding: 0 24px 18px 24px;

  /* Layout */
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    display: none;
  }
}

.organizationCopyrightMobile {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);
  transition: var(--transitionStyleButton);

  /* Tell parent component how much space this one prefers */
  flex-basis: 172px;

  margin-top: 0;
  margin-bottom: 0;

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }
}

.tosAndPrivacyMobile {
  flex-basis: 172px;
}

.privacy {
  margin-right: 24px;
}

.becomeATeacher {
  @apply --marketplaceButtonStyles;
  border-radius: 0;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;

  @media (--viewportMedium) {
    display: none;
  }
}
