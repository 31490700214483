.root {
  display: flex;
  flex-direction: column;
  align-items: center;

  /* Layout: size and positioning */
  width: 100%;
  height: auto;
  margin-top: 24px;

  & label[class*="requiredLabel"] {
    &:after {
      position: static;
      display: inline;
      margin-left: 5px;

      @media (--viewportLarge) {
        display: block;
        margin-left: 0;
      }
    }
  }

  & > div {
    width: 100%;
  }
}

.error {
  color: var(--failColor);
}

.errorPlaceholder {
  @media (--viewportMedium) {
    width: 100%;
    height: 32px;
  }
}

.reviewRating {
  margin-bottom: 18px;

  @media (--viewportMedium) {
    margin-bottom: 16px;
  }
}

.reviewContent {
  flex-shrink: 0;
  margin-top: 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-top: 30px;
  }
}

.submitButton,
.declineButton {
  /* margin-top: auto;
  margin-bottom: 96px; */
  flex-shrink: 0;
  width: calc(100% - 48px);
  margin: 40px 0 16px;

  @media (--viewportMedium) {
    display: inline-block;
    width: 100%;
    margin-top: 36px;
    margin-bottom: 15px;
  }
}

.checkbox {
  display: inline-block;
  margin-top: 30px;
  text-align: left;
  width: 100%;
}

.declineButton {
  margin-top: 0;
  margin-bottom: 96px;
}

.requiredLabel {
  padding: 0 24px;
  margin: 12px 0;

  @media (--viewportMedium) {
    padding: 0;
    margin: 0;
  }

  &:last-of-type {
    margin-top: 10px;
  }

  & label:after {
      content: '*';
     font-size: 20px;
      color: var(--failColor);

      @media (--viewportMedium) {
        content: '(required)';
        margin-left: 4px;
        font-size: 14px;
      }
    }
}
