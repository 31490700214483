@import '../../../styles/propertySets.css';

.quantityField {
  padding: 0 24px;

  & label {
    font-size: 14px;
    line-height: 20px;
    color: var(--matterColorDark);
  }
  @media (--viewportMedium) {
    padding: 0;
  }
}

.deliveryField {
  padding: 0 24px;
  margin-top: 24px;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.singleDeliveryMethodSelected {
  margin: 0;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.breakdownWrapper {
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
    margin-top: 40px;
  }
}

.breakdownTitle {
  font-size: 20px;
}

.submitButton {
  margin: 24px 24px 0;
  background: var(--marketplaceColor);
  border-radius: 6px;

  @media (--viewportMedium) {
    padding: 0;
    margin: 30px 0 0;
    /*padding: 0 24px;*/
  }

  &:hover {
     background-color: var(--marketplaceColorDarkAlt);
     color: var(--marketplaceColor);
   }
}

.button {
  @apply --marketplaceButtonStyles;
  color: var(--matterColorLight);
  background: var(--marketplaceColor);

  &:hover {
    background-color: var(--marketplaceColorDark);
    color: var(--matterColorLight);
  }
}

.signupLink {
  color: var(--matterColorLight);
  background: var(--successColor);
  height: 48px;
  padding: 12px 14px;
  border-radius: var(--borderRadius);
  font-size: 16px;
  align-items: center;
  justify-content: center;
  display: flex;
  min-width: 90px;
  font-weight: 700;

  &:hover,
  &:active,
  &:focus {
    background-color: var(--successColorDark);
    color: var(--matterColorLight);
    cursor: pointer;
  }
}

.checkbox {
  display: inline-block;
  margin-top: 30px;
}

.endButton {
  color: var(--matterColorLight);
  background: var(--marketplaceColorLight);
  margin-top: 30px;
}

.finePrint {
  @apply --marketplaceTinyFontStyles;
  text-align: center;
}

.error {
  @apply --marketplaceH4FontStyles;
  color: var(--failColor);

  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }
}

