
.root {
  fill: var(--marketplaceColor);
}

.blockList {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
}

.blockItem {
  height: 96px;
  flex: 0 0 100%;
  max-width: 100%;
  background: #EE4D680F;
  border-left: 6px solid var(--failColor);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 15px 0 10px;
  margin-bottom: 15px;


  &:nth-child(2n) {
     border-left: 6px solid #EFCA80;
     background: #EFCA800F;
   }

  &:nth-child(3n) {
     border-left: 6px solid #23A0EC;
     background: #1E8DD10F;
   }

  &:nth-child(4n) {
     border-left: 6px solid #EB94BD;
     background: #EB94BD0F;
   }

  @media (--viewportMedium) {
    flex: 0 0 49%;
    max-width: 49%;
  }

  @media (--viewportLargeWithPaddings) {
    flex: 0 0 23%;
    max-width: 23%;
  }
}

.blockTitle {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
}

.blockInfoHolder {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.blockInfo {
  font-size: 30px;
  font-weight: 700;
  line-height: 30px;
  height: 30px;
}

.blockDiff {
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  margin-left: 5px;
}
