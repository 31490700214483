@import '../../styles/customMediaQueries.css';
@import '../../styles/propertySets.css';

.root {
  fill: var(--marketplaceColor);
}

.modalTitle {
  text-align: center;
  font-size: 48px;
  line-height: 56px;
  font-weight: 700;
  margin-bottom: 40px;
}

.modalItem {
  margin-bottom: 40px;

  &:last-child {
    margin-bottom: 0;
  }
}

.modalItemTitle {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 17px;
}

.modalItemDescription {
  font-size: 20px;
  font-weight: 400;
  line-height: 1.5;
  border-radius: 6px 0 0 6px;
  border-left: 6px solid var(--marketplaceColor);
  background: #f2fafe;
  padding: 20px 20px;
}

.modalDescritionItem {
  margin-top: 0;
  font-size: 20px;
  line-height: 1.5;
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }
}

.modalContent {
  max-width: 100%;
  width: 100%;

  @media (--viewportMedium) {
    width: 604px;
  }
}

.openModalBtn {
  cursor: pointer;
  color: var(--marketplaceColor);
  border-bottom: 1px solid;

  &:hover, &:focus {
    color: var(--marketplaceColorDark);
  }
}