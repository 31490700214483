@import '../../styles/customMediaQueries.css';
@import '../../styles/propertySets.css';

.root {
  padding-top: 40px;

  @media (--viewportSmall) {
    padding-top: 0;
  }

  @media (--viewportMedium) {
    min-width: 670px;
  }
}

.title {
  @apply --marketplaceH1CustomFontStyles;
  margin: 0 0 23px;
  text-align: center;
}

.subTitle {
  @apply --marketplaceH2CustomFontStyles;
  text-align: center;
  margin: 0 0 43px;
  line-height: 35px;
}

.yellowDescription,
.blueDescription {
  margin: 0 0 30px;
  padding: 20px 32px 20px 20px;
  border-radius: 6px 0 0 6px;
  font-size: 20px;
  line-height: 30px;
}

.yellowDescription {
  border-left: 6px solid var(--attentionColor);
  background: var(--customLightYellowBg);
  list-style: disc;
  padding-left: 40px;

  & li {
    margin-bottom: 10px;
  }
}

.blueDescription {
  border-left: 6px solid var(--marketplaceColor);
  background: var(--customLightBlueBg);

  & b {
    color: var(--marketplaceColor);
  }
}

.copyBlock {
  @media (--viewportSmall) {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
}

.copyBlockLabel {
  margin: 0 0 5px;
  padding: 0;
}

.copyBlockContent {
  width: 100%;
  margin-bottom: 13px;

  @media (--viewportSmall) {
    width: calc(100% - 142px);
    margin-bottom: 0;
  }
}

.copyBlockLinkValue {
  padding: 16px 21px;
  border: 1px solid var(--matterColorAnti);
  margin: 0;
  border-radius: 6px;


  & span {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    line-height: 20px;
  }
}

.copyBlockButton {
  width: 100%;
  min-height: 54px;
  flex-shrink: 0;
  border-radius: 6px;

  @media (--viewportSmall) {
    width: 122px;
  }
}

.rememberText {
  margin: 0 0 13px;
  font-size: 20px;
  line-height: 30px;

  & a {
    text-decoration: underline;
    font-weight: var(--fontWeightRegular);
  }
}

.redText {
  color: var(--failColor);
}
