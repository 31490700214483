.root {
}

.select {
  color: var(--matterColorAnti);
  border-color: var(--matterColor);
  padding-right: 20px;
  width: 100%;


  & > option {
    color: var(--matterColor);
  }
  & > option:disabled {
    color: var(--matterColorAnti);
  }
}

.selectSuccess {
  color: var(--matterColor);
  border-color: var(--successColor);
}

.selectError {
  border-color: var(--failColor);
}

.selectHolder {
  display: flex;
  position: relative;
  font-size: 13px;

  @media (--viewportMedium) {
    font-size: 16px;
  }
}

.searchIcon {
  position: absolute;
  left: 17px;
  top: 9px;
  transform: translateY(50%);
}

.caret {
  border-bottom: 2px solid #51526C;
  border-right: 2px solid #51526C;
  width: 11px;
  height: 11px;
  transform: rotate(45deg);
  border-radius: 2px;
  position: relative;
  top: -1px;
  left: 4px;
}
