@import '../../../styles/propertySets.css';

.root {

}

.rootTitleHolder {
  display: flex;
  justify-content: center;
  margin: 0 auto 28px;
  text-align: center;
}

.rootTitle {
  @apply --marketplaceH2CustomFontStyles;
  border-bottom: 4px  solid var(--marketplaceColor);
  display: inline;
}

.rootDescription {
  font-size: 16px;
  line-height: 30px;
  margin: 0 auto 65px;
  text-align: center;

  @media (--viewportMedium) {
    font-size: 20px;
    max-width: 85%;
  }
}

.teachersInfoItemContent {
  /* display: flex; */
}

.teachersInfoItemTitle {
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 6px;
}

.underline {
  text-decoration: underline;
  font-weight: 400;
}

.teachersInfoItemDescription {
  margin: 0;

  @media (--viewportMLarge) {
    max-width: 464px;
  }
}

.referringLink {
  @apply --marketplaceMessageFontStyles;
  margin-top: 24px;
  /*font-size: 14px;*/
  cursor: pointer;
  color: var(--marketplaceColor);

  @media (--viewportLarge) {
    margin-top: 0;
  }

  &:hover,
  &:focus {
     color: var(--marketplaceColorDark);
   }
}

.teachersCarouselHolder {

  @media screen and (min-width: 1200px) {
    padding: 0 25px;
  }

  & :global(.alice-carousel__stage-item:nth-child(1n)) {
    & .teachersHolder {
      background: var(--bgColorFirst);
    }
    & .teachersInfoList {
      border-top: 1px solid var(--marketplaceColor);
    }
  }
  & :global(.alice-carousel__stage-item:nth-child(2n)) {
    & .teachersHolder {
      background: var(--bgColorSecond);
    }

    & .teachersInfoList {
      border-top: 1px solid var(--failColor);
    }
  }
  & :global(.alice-carousel__stage-item:nth-child(3n)) {
    & .teachersHolder {
      background: var(--bgColorThird);
    }

    & .teachersInfoList {
      border-top: 1px solid var(--customYellow);
    }
  }
  & :global(.alice-carousel__stage-item:nth-child(4n)) {
    & .teachersHolder {
      background: var(--bgColorFourth);
    }

    & .teachersInfoList {
      border-top: 1px solid var(--customPink);
    }
  }

  & :global(.alice-carousel__prev-btn),
  & :global(.alice-carousel__next-btn) {
      display: inline-block;
      box-sizing: border-box;
      width: auto;
      padding: 15px 10px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);

      @media (--viewportLarge) {
        transform: translateY(-100%);
      }
    }


  & :global(.alice-carousel__prev-btn) {
      text-align: right;
      left: -15px;
      padding-left: 0;

      @media (--viewportLarge) {
        left: -50px;
      }
    }

  & :global(.alice-carousel__next-btn) {
      right: -15px;
      padding-right: 0;
      @media (--viewportLarge) {
        right: -50px;
      }
    }

}

.teachersWrapper {
  display: block;
  margin: 8px;
  padding: 0;
  color: var(--matterColor);
  border-radius: 6px;
  max-width: 360px;

  &:hover {
    color: var(--matterColor);
   }
}

.teachersWrapperBlocked {
  pointer-events: none;
}

.teachersHolder {
  padding: 15px;
  height: 300px;
}

.teachersTitle {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  margin: 20px 0 5px;


  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0;
  min-height: 30px;
}

.teachersBio {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  margin-top: 5px;
  margin-bottom: 10px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0;
  min-height: 38px;
}

.teachersInfoList {
  padding-top: 8px;
}

.teachersInfoItem {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 10px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0;
}

.teachersInfoSpan {
  font-weight: 400;
  margin: 0 5px;
}

.teachersInfoSpanBg {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 6px;
}

.imageContainer {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  /* padding-top: 100%; */
}

.image {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: auto;
  }

.itemsHolder {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.itemParent {
  font-weight: 700;

  &:after {
     content: ', ';
   }

  &:last-child {
  &:after {
     content: '';
   }
  }
}

.itemParentValue {

}

.subItem {
  font-size: 16px;
  font-weight: 400;
}

.itemSpan {
  font-weight: 400;
  text-transform: capitalize;
  &:after {
     content: ', ';
   }

  &:last-child {
  &:after {
     content: '';
   }
  }
}

.aspectRatioWrapper {
  transition: var(--transitionStyleButton);
  /*background: var(--matterColorNegative); !* Loading BG color *!*/
  /*border-radius: 8px;*/
  height: 220px;
  overflow: hidden;

  @media (--viewportMedium) {
    /*&:hover {*/
    /*  !* transform: scale(1.02); *!*/
    /*  box-shadow: var(--boxShadowListingCard);*/
    /*}*/
  }
}

.rootForImage {
  /*border: solid 1px var(--matterColorNegative);*/
  /*border-radius: 8px;*/
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.buttonProfile,
.primaryButton {
  color: var(--matterColorLight);
  background: var(--failColor);
  width: 100%;
  max-width: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px auto 0;
  height: 56px;
  border-radius: 6px;
  font-size: 20px;
  font-weight: 700;

  &:hover {
     background-color: var(--failColorDark);
     color: var(--matterColorLight);
   }
}

.primaryButton {
  margin-bottom: 60px;
}

.buttonProfile {
  background: var(--marketplaceColor);
  max-width: 250px;
  margin-top: 0;
  font-size: 14px;
  font-weight: 700;
  height: 37px;

  &:hover {
    background-color: var(--marketplaceColorDark);
    color: var(--matterColorLight);
  }
}
